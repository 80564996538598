@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

@font-face {
  font-family: "Open Sans";
  src: url("./fonts/OpenSans/OpenSans-VariableFont.ttf");
}

* {
  box-sizing: border-box;
}

body {
  background: white;
  min-height: 100vh;
  display: flex;
  font-weight: 500;
  font-family: "Open Sans";
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Open Sans";
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}