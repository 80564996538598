:root {
  --darkBlue: #232137;
  --lightBlue: #1E56A4;
  --accentYellow: #FFEAA7;
  --mainBgColor: #F8F9F9;

  --shadow: rgba(70, 65, 65, .3);
  --transparentBlue: rgba(22, 103, 242, 0.5);
}

html {
  font-size: 13px;
}

.App {
  min-height: 100vh;
  height: auto;
  display: flex;
}

a:hover {
  color: var(--darkBlue);
}

.mobileBlockCopy {
  display: none !important;
}

.authBlock {
  width: 100%;
  display: flex;
}

.mainPage {
  width: 100%;
  display: flex;
  background-color: var(--mainBgColor);
  flex-direction: column;
}

.sideMenu {
  display: flex;
  padding: 2rem 2.5rem;
}

.userName {
  display: block;
  text-align: center;
}

.sideMenu ul {
  flex: 1;
  border: .0625rem solid var(--transparentBlue);
  background: white;
  box-shadow: 0 0 .125rem .0625rem var(--shadow);
  border-radius: 3.125rem;
  list-style: none;
  padding: 2.5rem;
  margin-bottom: 0;
}

.sideMenu li a {
  color: var(--darkBlue);
  font-size: 1.5rem;
  padding: .1875rem 0;
  margin-bottom: .6rem;
  display: flex;
  align-items: center;
}

.nav-icon {
  margin-right: 1.875rem;
  color: var(--lightBlue);
}

div.nav-icon {
  width: 1.55rem;
  height: 1.55rem;
  background-size: 86%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.nav-name {
  flex: 1;
  font-weight: 100;
  white-space: nowrap;
  border: .225rem solid transparent;
  line-height: 1.3;
  padding: .3125rem .94rem;
}

.activeMenuLink .nav-name {
  border-color: var(--lightBlue);
  border-radius: 1.875rem;
}

.sideMenu li a:hover,
.sideMenu li a:focus {
  color: #3b3f44;
}

.mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 2rem 3.75rem 1.875rem 2rem;
}

.mainBlock .content {
  max-width: 60rem;
  width: 100%;
}

.mainBlock .content.wideContent {
  max-width: 100%;
}

.mainBlock .content.reportContent {
  max-width: 70rem;
}

.mainBlock .content.dashboard {
  max-width: 75rem;
}

.navbar {
  background-color: white;
  border-radius: 0 0 1.25rem 1.25rem;
  padding: 1.125rem 0;
  border-bottom: .0625rem solid var(--transparentBlue);
  box-shadow: 0 0 .125rem .0625rem var(--shadow);
}

.navbar .container {
  max-width: 94%;
}

.headerRightBlock {
  display: flex;
  justify-content: space-between;
}

.profileLink {
  background: #859fb0;
  display: flex;
  margin-right: 1.25rem;
  padding: .375rem .875rem;
  font-size: 1.4rem;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.marginR10 {
  margin-right: .625rem;
}

.marginR15 {
  margin-right: .94rem;
}

.contentWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.content .pageTitle {
  font-size: 1.675rem;
  text-transform: uppercase;
  color: var(--darkBlue);
  margin-bottom: 1.25rem;
}

.content .pageTitle span {
  font-weight: 500;
}

.content .subtitle {
  font-size: 1.688rem;
  color: var(--darkBlue);
  margin-bottom: .625rem;
}

.pageContent {
  background: white;
  min-height: 43.75rem;
  padding: 1.25rem 2.2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: .0625rem .0625rem .3125rem rgba(100, 100, 100, 0.1);
}

.pageContent .buttonBlock {
  margin-top: auto;
}

.dashboardBlock {
  border-radius: .3125rem;
}

.dashboardTop {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: .625rem;
  align-items: center;
  background: white;
  color: var(--darkBlue);
  padding: .725rem 1.875rem;
  margin-bottom: 2.5rem;
  border: 0.0625rem solid var(--transparentBlue);
  box-shadow: 0 0 0.125rem 0.0625rem var(--shadow);
}

.totalTodaySent {
  font-size: 1.388rem;
}

.totalTodaySent p {
  font-weight: 400;
  margin: 0;
}

.totalTodaySent span {
  font-weight: 100;
}

.dashboardTopRight {
  display: flex;
}

.dashboardTopBlock {
  display: flex;
  font-size: 1.388rem;
  align-items: center;
}

.dashboardTopBlock:not(:first-child) {
  border-left: .0625rem solid var(--darkBlue);
  padding-left: .625rem;
  margin-left: .625rem;
}

.dashboardTopBlock span {
  font-weight: 100;
}

.dashboardTopBlock .dashboardTopBold {
  font-weight: 400;
}

.dashboardTopBlock .mainBtn {
  padding: .7rem 1.2rem;
}

.tariffPlansBtn {
  border: none;
  background: transparent;
  font-family: "Open Sans";
  color: var(--darkBlue);
  padding: 0;
}

.step .tariffPlansBtn {
  color: var(--lightBlue);
  text-decoration: underline;
}

.tariffPlansBtn:hover {
  text-decoration: underline;
  text-decoration-color: var(--darkBlue);
}

.emptyDialog {
  background: white;
  padding: 1.25rem;
  border-radius: .625rem;
}

.tariffPlansModal .modal-dialog {
  max-width: 90rem;
}

.signupPageModal .modal-dialog {
  max-width: 80rem;
}

.tariffPlansModal .modal-content {
  background: transparent;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border: none;
}

.carouselParent {
  width: inherit;
}

.react-multi-carousel-list {
  padding-bottom: 32px;
}

.react-multi-carousel-list .react-multi-carousel-track {
  margin: 0 auto;
}

.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.react-multi-carousel-dot {
  display: flex;
  align-items: center;
}

.react-multiple-carousel__arrow {
  bottom: 2px;
}

.react-multi-carousel-list .react-multiple-carousel__arrow--left {
  left: calc(50% - 120px);
}

.react-multi-carousel-list .react-multiple-carousel__arrow--right {
  right: calc(50% - 120px);
}

.carousel-dot-list-style {
  height: 50px;
}

.tariffPlanTile {
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  margin: .625rem;
  background: white;
  color: var(--darkBlue);
  border: .0625rem solid var(--darkBlue);
  border-radius: 1.875rem;
  font-size: 1.313rem;
  transition: transform 500ms ease;
  width: 340px;
  height: 90%;
}

.tariffPlanTile:hover {
  transform: scale(1.05);
}

.tariffPlanHeader {
  display: flex;
  margin-bottom: 1rem;;
}

.tariffPlanHeader div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tariffPlanName {
  width: 60%
}

.tariffPlanBought .tariffPlanName {
  width: 100%;
}

.tariffPlanName p {
  display: flex;
  font-size: 1.7rem;
  font-weight: bold;
  border-bottom: 2px var(--lightBlue) solid;
  word-break: break-all;
}

.tariffPlanPrice {
  width: 40%;
  padding: 0 3px;
  white-space: nowrap;
  border: 2px dotted var(--lightBlue);
  border-radius: 1.25rem;
}

.tariffPlanPrice p {
  font-size: 3.2rem;
  color: var(--lightBlue);
  font-weight: bold;
}

.tariffPlanBody {
  margin-bottom: 1rem;
}

.tariffPlanBody p span {
  font-weight: 400;
}

.tariffPlanTile p {
  margin-bottom: 0;
}

.tariffPlanTile .btn {
  width: auto;
  align-self: center;
  margin-top: auto;
  font-size: 1.125rem;
  padding: 1rem 2.5rem;
}


.balance {
  margin: 0 1rem;
}

.balance:before {
  content: '€'
}

.buttonBlock {
  text-align: end;
}

.mainBtn,
.mainBtn.disabledBtn.btn:disabled,
.mainBtn.disabledBtn.btn:disabled:hover {
  font-family: "Open Sans";
  border: .0625rem solid var(--lightBlue);
  font-size: 1.3rem;
  border-radius: 2.188rem;
  background: var(--lightBlue);
  color: white;
  padding: .625rem 2.5rem;
  line-height: 1;
}

.mainBtn:hover {
  border-color: var(--lightBlue);
  color: var(--darkBlue)
}

.headerRightBlock .mainBtn,
.headerRightBlock .mainBtn.disabledBtn.btn:disabled,
.headerRightBlock .mainBtn.disabledBtn.btn:disabled:hover {
  border: .0625rem solid white;
  background: var(--lightBlue);
  color: white;
  border-radius: 1.875rem;
  font-size: 1.4rem;
  font-weight: 400;
  padding: .6875rem 1.25rem;
}

.headerRightBlock .mainBtn:hover {
  background: var(--darkBlue);
}

.btn-success {
  background-color: #34c38f;
  border-color: #34c38f;
  color: #fff;
}

.btn-success:hover {
  background-color: #2ca67a;
  border-color: #2a9c72;
  color: #fff;
}

.btn-rounded {
  border-radius: 1.875rem;
}

.btn.disabledBtn:disabled,
.btn.disabledBtn:disabled:hover {
  opacity: .6;
}

.dashboardMain {
  display: flex;
  padding: 0 0 1.25rem;
}

.diagram {
  flex: 1;
}

.diagramTitle {
  display: flex;
  margin-bottom: .9em;
  justify-content: center;
}

.diagramTitle span {
  display: flex;
  font-size: 1.488rem;
  font-weight: 500;
}

.chartLegend {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
.chartLegend li {
  display: flex;
  align-items: center;
  margin-left: 2em;
  font-size: 1.2rem;
}

.chartLegend li div {
  width: 1.4em;
  height: .7em;
  border-radius: .4em;
  margin-right: 1em;
}

.diagramBody {
  display: flex;
  height: 60vh;
}

.diagramBody canvas {
  width: 100% !important;
  max-height: 100%;
}

.logo,
.logo:active,
.logo:visited,
.logo:focus {
  width: 14rem;
  color: white;
  text-decoration: none;
  line-height: 1;
}

.logo img,
.loginPageLogo img {
  width: 100%;
}

.auth-logo {
  position: absolute;
  left: 2.5rem;
  top: 2.5rem;
}

.auth-logo .logo {
  width: 20rem;
}

.auth-container {
  width: 100%;
  position: relative;
  background: var(--mainBgColor);
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-wrapper {
  max-width: 50rem;
  height: auto;
  background: white;
  padding: 1.25rem 3.75rem;
  overflow: auto;
  text-align: center;
  box-shadow: 0 .0625rem .1875rem 0 var(--shadow);
}

.forgotPasswordFormWrapper {
  display: flex;
  min-height: 19rem;
}

.forgotPasswordFormWrapper form {
  flex: 1;
}

.forgotPasswordMessageWindow {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 1.688rem;
}

.loginPageLogo {
  margin: .625rem auto 4.375rem;
  border-radius: .625rem;
  width: 6.25rem;
}

.loginLabel {
  display: block;
  font-size: 1.688rem;
  color: var(--darkBlue);
}

.loginInput,
.loginInput:-webkit-autofill,
.loginInput:-webkit-autofill:hover,
.loginInput:-webkit-autofill:focus,
.loginInput:-webkit-autofill:active,
.loginInput:-internal-autofill-selected {
  width: 100%;
  background-color: transparent;
  border: .125rem solid var(--shadow);
  border-radius: 2.188rem;
  outline: none;
  padding: .125rem 1.563rem;
  font-size: 1.688rem;
  font-weight: 300;
  -webkit-box-shadow: 0 0 0 1.875rem white inset !important;
  box-shadow: 0 0 0 1.875rem white inset !important;
  -webkit-text-fill-color: black !important;
}

::placeholder {
  color: #9b9b9b;
  -webkit-text-fill-color: #9b9b9b !important;
}

.loginBtn {
  margin-bottom: 5rem;
}

.loginLink,
.loginLink:visited,
.loginLink:active,
.loginLink:focus,
.loginLink:hover {
  color: var(--lightBlue)
}

button.loginLink {
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.loginLinkText {
  color: var(--darkBlue);
  margin-bottom: 0;
  font-size: 1.5rem;
}

.msgBlock {
  position: relative;
  padding: .625rem;
  border-radius: .94rem;
  margin-bottom: .3125rem;
  font-size: 1.2rem;
}

.msgBlock:before {
  display: inline-block;
  position: relative;
  border: .0625rem solid transparent;
  border-radius: 50%;
  margin-right: .4375rem;
  width: 2rem;
  text-align: center;
  font-weight: bold;
}

.errorMsg {
  color: white;
  background: red;
}

.warningMsg {
  background: #ffeb3b;
  color: black;
}

.successMsg {
  color: white;
  background: #2ca67a;
}

.warningMsg:before {
  content: '!';
  border-color: black;
}

.errorMsg:before {
  content: '-';
  border-color: white;
}

.successMsg:before {
  content: '✔';
  border-color: white;
}

/* modal start */

.modal-dialog {
  max-width: 40.625rem;
  font-family: 'Open Sans';
}

.modal-content {
  border-radius: 2.5rem;
  border: .0625rem solid var(--darkBlue);
  padding: 1.25rem .625rem .3125rem;
}

.modal-backdrop+.modal:last-child {
  z-index: 1060;
}

.modal+.modal-backdrop {
  z-index: 1059;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: .875rem 1.25rem;
  border-color: var(--lightBlue);
}

.modal-header .pageTitle {
  font-size: 1.375rem;
  font-weight: 900;
  color: var(--darkBlue);
}

.modal-body {
  font-size: 1.25rem;
  color: var(--darkBlue);
}

.modal-body .subtitle {
  color: var(--darkBlue);
  font-size: 1.438rem;
}

.modal-footer .mainBtn {
  font-size: 1.313rem;
  padding: .625rem 1.625rem;
  margin-left: .625rem;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancelBtn {
  background-color: silver;
  border-color: silver;
}

button.btn-close {
  width: .875rem;
  height: .875rem;
  color: var(--darkBlue);
  opacity: .8;
  background-size: .875rem;
}

button.btn-close,
button.btn-close:focus {
  box-shadow: none;
}

.form-check {
  font-size: 1.438rem;
  color: var(--darkBlue);
  margin-bottom: 0;
}

.form-check-input {
  width: .875rem;
  height: .875rem;
  border-color: var(--darkBlue);
  margin-top: .625rem;
}

.form-check-input:checked[type=radio] {
  background-image: none;
}

.form-check-input:checked {
  background-color: var(--lightBlue);
  border-color: var(--darkBlue);
}

/* modal end */

/*Campaigns start*/
.myTable {
  background: #fff;
  border-radius: 0.25rem;
  padding: .7rem 2rem 3rem;
  box-shadow: 0 .0625rem .1875rem 0 var(--shadow);
}

.tableHeader {
  padding: .625rem;
  display: flex;
  flex-direction: column;
}

.tableHeaderInline {
  flex-direction: row;
}

.tableBtnCol {
  display: flex;
  flex: 1;
}

.tableHeader .mainBtn,
.tableBtnCol .mainBtn {
  align-self: flex-end;
}

.tableHeaderInline .mainBtn,
.tableBtnCol .mainBtn  {
  margin-left: auto;
}

.tableHeaderInline .customSelect .select__value-container {
  font-size: 1.3rem;
}

.myTable .table {
  margin-bottom: 0;
  color: var(--darkBlue);
  min-width: 31.25rem;
}

.myTable .table th,
.myTable .table td {
  border-bottom-width: .125rem;
  border-bottom-color: var(--lightBlue);
}

.myTable .table th {
  padding-left: 0;
  font-size: 1.325rem;
  font-weight: 500;
}

.myTable .table tbody {
  border-top: .125rem solid #eff2f7;
}

.myTable .table td {
  padding: .7rem 1rem;
  font-size: 1.3rem;
  font-weight: 400;
}

.iconColumn {
  width: 3.125rem;
  text-align: center;
}

.tableIconBtn {
  display: block;
  color: var(--darkBlue);
}

.tableIconBtn svg {
  transition: transform 500ms ease;
}

.tableIconBtn:hover svg {
  transform: scale(1.2);
}

button.tableIconBtn {
  border: none;
  background: transparent;
  padding: 0;
}

.emptyTableMessage {
  text-align: center;
  font-size: 1.563rem;
  color: var(--darkBlue);
  margin: 0;
}

.tableFiltersCol {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding-right: 10px;
}

.tableFiltersCol .filterCol {
  flex: 1;
}

.tableFiltersCol .filterCol:first-child {
  padding-right: 10px;
}

.filterCol .searchInput {
  width: 100%;
}

/*Campaigns end*/

/*Create campaign start*/
.step {
  margin: 1.25rem auto;
  background: white;
}

.stepTitle {
  background: var(--lightBlue);
  border-radius: 0 0 1.25rem 1.25rem;
  padding: .625rem 1.875rem;
  color: white;
  font-size: 1.488rem;
  text-transform: uppercase;
}

.stepBody {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 2rem;
}

.inputLabel,
.wizardText {
  font-size: 1.425rem;
  color: var(--darkBlue);
  margin-bottom: .75rem;
}

.finalStep .stepBody .wizardButtons {
  margin-top: 2.5rem;
}

.step3 {
  position: relative;
}

.wizardInput,
.wizardTextArea,
.formInput,
.searchInput {
  display: block;
  background-color: transparent;
  border: .2rem solid rgba(70, 65, 65, .5);
  border-radius: 1.875rem;
  outline: none;
  padding: .325rem 1.563rem;
  font-size: 1.4rem;
  font-weight: 300;
  width: 100%;
  margin: 0;
}

.shortInput {
  max-width: 25rem;
}

.requiredInput {
  position: relative;
}

.requiredInput:after {
  position: absolute;
  display: block;
  content: '*';
  top: 0;
  right: -1.25rem;
}

.inputWrapper {
  position: relative;
  margin-bottom: 1.5rem;
}

.inputWrapperNoMargin {
  margin-bottom: 0;
}

.fieldError {
  color: red;
  position: absolute;
  bottom: -1.25rem;
  left: .625rem;
  font-size: 1.2rem;
  line-height: 1em;
}

.loginLinkText+.fieldError {
  display: block;
  position: static;
}

.invalidInput {
  border-color: red;
}

.customSelect .select__control {
  border-radius: 1.563rem;
  background-color: transparent;
  border: .2rem solid rgba(70, 65, 65, .5);
  min-height: auto;
  font-weight: 300;
}

.customSelect .select__control.select__control--menu-is-open {
  border-color: var(--lightBlue);
}

.customSelect .select__value-container {
  font-size: 1.4rem;
  text-align: left;
  padding: .325rem 1.563rem;
}

.customSelect .select__input-container {
  margin: 0;
  padding: 0;
}

.customSelect .select__dropdown-indicator {
  padding: .125rem 1.5rem .125rem .75rem;
}

.customSelect .select__menu {
  text-align: left;
  font-size: 1.563rem;
  z-index: 4;
}

.select__menu-list .select__option.select__option--is-selected {
  background-color: var(--lightBlue);
}

.wizardTextArea,
.formTextArea {
  resize: none;
  height: 10rem;
  padding-top: .3125rem;
}

.wizardValue {
  display: block;
  color: #292929;
  font-weight: normal;
}

.wizardButtons {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.wizardButtons .subBlock {
  display: flex;
  flex: 1;
}

.wizardButtons .subBlock .mainBtn {
  margin-right: 1rem;
}

.stepBody .mainBtn.next {
  margin-left: 1.875rem;
}

.stepBody .mainBtn.delete {
  background: #ff5858;
  border-color: #ff5858;
}

.stepBody .mainBtn.delete:hover {
  background: white;
}

.fileUploadWrapper {
  display: flex;
  justify-content: flex-start;
}

.browseFileBtn {
  flex: 1;
  background: #a2a4a4;
  color: white;
  border: .125rem solid #a2a4a4;
  border-radius: 1.563rem 0 0 1.563rem;
  padding: .125rem 1.25rem;
  font-size: 1.5rem;
}

.browseFileBtn:hover {
  color: white;
  background: var(--lightBlue);
}

.fileName {
  display: flex;
  align-items: center;
  padding: 0 10px;
  flex: 4;
  border: .125rem solid #a2a4a4;
  border-radius: 0 1.563rem 1.563rem 0;
}

.removeFileBtn {
  flex: 1;
  border-radius: 2.188rem;
  background: tomato;
  font-family: "Open Sans";
  font-size: 1.3rem;
  color: white;
  border: .0625rem solid tomato;
}

.removeFileBtn:hover {
  color: tomato;
  border-color: tomato;
  background: white;
}

/*Create campaign end*/

/*Campaign view start*/
.pageBody {
  background-color: #fff;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  padding: 1.25rem;
}

/*Campaign view end*/

/*Send SMS start*/
.sendSmsBlock {
  position: relative;
  min-height: .0625rem;
}

.c7b,
.c7eb,
.c16b,
.c16bf,
.udh span {
  display: inline-block;
  width: 1.5rem;
  height: 1.938rem;
  text-align: center;
  background-color: #eee;
  border-radius: .1875rem;
  margin: 0;
  line-height: 1.875rem;
  vertical-align: top;
  overflow: hidden;
  border-right: .0625rem solid #fff;
  border-bottom: .0625rem solid #fff
}

.c7eb {
  background-color: #fea;
  font-size: .625rem
}

.c16b {
  background-color: #8eba33
}

.c16bf {
  background-color: #698924
}

.udh span {
  font-size: .5625rem;
  background-color: #b1deff
}

.sms-length-calculator .messageContent,
.detailsBlock {
  border-bottom: .125rem solid var(--transparentBlue);
  margin-bottom: .625rem;
}

.sms-length-calculator .sms-calculator-summary {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1.5rem;
  border: .2rem solid rgba(70, 65, 65, .5);
  border-radius: 1.875rem;
}

.smsInfoBlock {
  padding: .8rem 1.363rem;
  margin: 0 1rem;
  font-size: 1.3rem;
  line-height: 1.625rem;
  text-align: center;
  flex: 1;
}

.smsInfoBlock .smsInfoBlockTitle {
  border-bottom: .125rem solid var(--lightBlue);
  padding-bottom: .94rem;
  margin-bottom: .94rem;
}

.smsInfoBlock .smsInfoBlockTitle~p {
  font-weight: bold;
}

.smsInfoBlock p {
  margin: 0;
  color: #2e2e2e;
}

.sms-length-calculator .sms-part {
  padding-bottom: .625rem;
}

.detailsBlock .sendSmsBlock~p {
  font-size: 1.3rem;
}

/*Send SMS end*/

/*SMS Templates start*/

.react-bootstrap-table {
  margin-bottom: 2.5rem;
}

.react-bootstrap-table-pagination .btn-secondary {
  background: #a3a7a7;
  border-color: #a3a7a7;
  border-radius: .94rem;
  font-size: 1.5rem;
  padding: .5rem .625rem;
  font-family: 'Open Sans';
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

.react-bootstrap-table-pagination-total {
  font-size: 1.5rem;
  color: var(--darkBlue);
  font-weight: 100;
  margin-left: .94rem;
}

.page-link {
  font-size: 1.5rem;
  padding: .625rem 1.375rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: .94rem;
  border-bottom-left-radius: .94rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: .94rem;
  border-bottom-right-radius: .94rem;
}

.page-link {
  color: var(--lightBlue);
}

.active>.page-link, .page-link.active {
  background-color: var(--lightBlue);
  border-color: var(--lightBlue);
}

/*SMS Templates end*/

/*Contacts start*/

.extraFieldsBtn,
.extraFieldsBtn:hover,
.extraFieldsBtn.disabledBtn:disabled {
  width: 5rem;
  background: green;
  border-radius: 1.875rem;
  color: white;
  padding: .1875rem 1.25rem;
  font-size: 1.5rem;
}

.deleteBtn,
.deleteBtn:hover {
  background: red;
}

.trashBtn {
  color: #ff5858;
}

.extraFieldDialog {
  max-width: 25rem;
  display: flex;
  position: relative;
}

.extraFieldDialog input {
  border-radius: 1.875rem 0 0 1.875rem;
}

.extraFieldDialog .extraFieldsBtn {
  border-radius: 0 1.875rem 1.875rem 0;
}

.extraFieldDialog .fieldError {
  bottom: -1.06rem;
}

/*Contacts end*/

/* Prices start */

.searchInput {
  align-self: flex-end;
  width: 16.875rem;
}

.sortable {
  cursor: pointer;
}

.sortable .caret {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .625rem;
  vertical-align: middle;
  border-top: .5rem solid;
  border-right: .5rem solid transparent;
  border-left: .5rem solid transparent;
}

.order .caret {
  color: #ccc;
}

.dropup .caret {
  border-top: 0;
  border-bottom: .5rem dashed;
}

/* Prices end */

.dateTimePickerBlock {
  display: flex;
  margin-bottom: 1rem;
}

.dateTimePickerBlock .react-datepicker-wrapper:first-child {
  flex: 2;
  margin-right: 10px;
}

.dateTimePickerBlock .react-datepicker-wrapper {
  flex: 1;
}

.datePicker {
  position: relative;
}

.datePicker:after {
  display: block;
  position: absolute;
  content: 'o'
}

.react-datepicker__triangle {
  transform: translate(40px, 0) !important;
}

/* Individual SMS Report start */

.tableHeader .dateTimePickerBlock {

}

.tableHeader .mb-3 {
  display: flex;
  flex-direction: column;
}

.tableHeader .mb-3.fewFilters {
  flex-direction: row;
}

.tableHeader .mb-3 .customSelect {
  flex: 1;
}

.tableHeader>.col-6:first-child {
  padding-right: 10px;
}

.tableHeader .col-6 .searchInput {
  width: 100%;
}

/* Individual SMS Report end */

/* Support start */

.supportPage p {
  font-size: 1.4rem;
  margin-bottom: .6rem;
}

.supportPage p.br {
  margin-bottom: 3rem;
}

/* Support end */

.waitingAnimation {
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .7);
  z-index: 1;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.waitingAnimation .rli-text-format {
  font-size: 1em;
}

/* media start*/

@media only screen and (max-width: 1440px) {

  html {
    font-size: 12px;
  }

}

@media only screen and (max-width: 1368px) {

  .tableHeaderInline {
    flex-direction: column-reverse;
  }

  .tableFiltersCol {
    width: 100%;
    padding: 0;
    margin-top: 10px;
  }

}

@media only screen and (max-width: 1024px) {

  html {
    font-size: 10px;
  }

}

@media only screen and (max-width: 768px) {
  /* Tablets */

  html {
    font-size: 10px;
  }

  .desctopBlockCopy {
    display: none;
  }

  .mobileBlockCopy {
    display: flex !important;
  }

  .mainPage {
    background-color: white;
  }

  .contentWrapper {
    position: relative;
    width: 100vw;
  }

  .sideMenu {
    position: absolute;
    padding: 0;
    padding-top: 8rem;
    z-index: 10;
  }

  .sideMenu ul {
    border-radius: 0 1rem 1rem 0;
    padding: 1.6rem .8rem 1.6rem 1.2rem;
  }

  .sideMenu li a {
    font-size: 3rem;
  }

  .nav-icon {
    margin-right: 0;
    padding: 1rem;
    width: 3rem;
    border: 2px solid transparent;
    border-radius: 50%;
  }

  div.nav-icon {
    width: 5rem;
    height: 5rem;
    background-size: 55%;
  }

  .activeMenuLink .nav-icon {
    border-color: var(--lightBlue);
  }

  .nav-name {
    display: none;
  }

  .mainBlock {
    width: 100%;
    background: white;
    padding: 2rem 0;
  }

  .dashboardBlock {
    margin-top: -1.6rem;
  }

  .dashboardTop {
    border-radius: 0 0 1rem 1rem;
    align-items: start;
  }

  .dashboardTopLeft {
    padding-left: 6rem;
    display: flex;
    align-self: stretch;
    flex-direction: column;
  }

  .dashboardTopRight {
    flex-direction: column;
    align-items: end;
  }

  .dashboardTop .dashboardTopBlock {
    border-left: none;
    margin-bottom: .5rem;
  }

  .dashboardTopBlock {
    flex: 1;
  }

  .dashboardTopBlock:not(:first-child) {
    margin-left: 0;
    padding-left: 0;
  }

  .dashboardTopBlock .mainBtn {
    white-space: nowrap;
  }

  .balance {
    margin: 0;
  }

  .dashboardMain {
    flex-direction: column-reverse;
    padding-left: 7.5rem;
    padding-right: .5rem;
  }

  .diagramTitle {
    margin-bottom: .5em;
    padding-left: 1em;
  }

  .content .pageTitle {
    padding-left: 2rem;
    font-weight: bold;
  }

  .content .pageTitle+.row {
    margin: 0;
  }

  .content .pageTitle+.row .col-lg-12 {
    padding: 0;
  }

  .myTable {
    padding: .7rem 0;
  }

  .myTable .react-bootstrap-table {
    padding-left: 7.3rem;
    min-height: 55rem;
    border-bottom: 2px solid rgba(150, 150, 150, .2);
  }

  .tableHeader {
    padding-left: 8rem;
  }

  .tableHeader .fewFilters .col-6 {
    width: 100%;
  }

  .reportContent .tableHeader .fewFilters {
    flex-direction: column;
  }

  .tableHeader .col-6:first-child {
    padding: 0;
  }

  .myTable .table {
    min-width: auto;
  }

  .myTable .table th {
    padding: .5rem 1rem;
    white-space: nowrap;
  }

  .react-bootstrap-table-pagination {
    margin: 0;
  }

  .react-bootstrap-table-pagination .col-md-6 {
    width: 50%;
  }

  .pageContent,
  .stepBody,
  .pageBody {
    padding: 1rem 1rem 1rem 8.5rem;
  }

  .pageBody {
    font-size: 1.3rem;
  }

  .pageContent .mb-3,
  .stepBody .mb-3,
  .messageContent {
    display: flex;
    flex-direction: column;
  }

  .inputLabel {
    align-self: center;
  }

  .formTextArea {
    height: 20rem;
  }

  .sms-length-calculator .sms-calculator-summary {
    padding: 1rem;
  }

  .smsInfoBlock {
    margin: .5rem;
    white-space: nowrap;
  }

  .sms-length-calculator .messageContent {
    border: none;
  }

  .detailsBlock {
    margin: 0 -1rem .625rem -8.5rem;
    border-top: 1px solid var(--darkBlue);
    border-bottom: 1px solid var(--darkBlue);
    padding: 1rem 2rem;
  }

  .pageContent .buttonBlock,
  .stepBody .wizardButtons {
    text-align: center;
    margin-left: -8.5rem;
    margin-right: -1rem;
  }

  .finalStep .stepBody .wizardButtons {
    margin-top: auto;
  }

  .extraFieldDialog {
    max-width: none;
  }

  .step {
    margin-top: -1.6rem;
  }

  .stepTitle {
    font-size: 1.4rem;
    border-radius: 0 0 1rem 1rem;
  }

  .stepBody {
    min-height: 63rem;
  }

  .fileUploadWrapper {
    white-space: nowrap;
  }

  .tariffPlansModal .modal-dialog {
    margin: 2rem
  }

  .modal-content {
    border-radius: 1.5rem;
    padding: 0;
  }

  .tariffPlanTile {
    font-size: 1rem;
  }

  .tariffPlanTile div {
    margin: 1.5rem 0;
  }

  .supportPageContent {
    padding-left: 8rem;
  }
}

@media only screen and (max-width: 480px) {
  /* Phones */

  .auth-container {
    flex-direction: column;
  }
  .auth-logo {
    position: static;
    margin-bottom: 2rem;
  }

}

/* media end*/